import { Ref, ref } from 'vue';
import { createURL } from '@/utils';

import {
  AuthorizeUrl,
  mapApp,
  IappValue,
} from '../config/login/index';


interface iframeUrlInterface {
  iframeUrl: Ref<string>
}

const { href } = location;

let envObj: IappValue;

for (const [, value] of Object.entries(mapApp)) {
  if (href.indexOf(value.CallBackUrl) !== -1) {
    envObj = value;
  }
}


export function useIframeUrl(): iframeUrlInterface {
  const iframeUrl = ref('');
  iframeUrl.value = createURL({
    response_type: 'token',
    client_id: envObj.APPID,
    redirect_uri: envObj.CallBackUrl,
  }, AuthorizeUrl);

  console.log(iframeUrl.value, 'ifrmae');

  return {
    iframeUrl,
  };
}
