<template>
  <div class="wrapper">
    <vSiderBar :showSider="false"></vSiderBar>
    <div class="main">
      <div class="tip-container" v-if="init">
        <img :src="nologintip" alt="" />
        <div class="login-container">
          <p class="tip">很抱歉，您尚未登录，请登录后使用本产品</p>
          <el-button class="btn" type="primary" @click="login">去登录</el-button>
        </div>
      </div>
      <div class="tip-container" v-else>
        <img :src="touristTip" alt="" />
        <div class="login-container">
          <p class="error-tip">登录失败，请联系管理员确认权限</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import Cookies from 'js-cookie';
import { useIframeUrl } from '../../reactivity/createIframeUrl';
import nologintip from '../../assets/img/nologintip.png';
import touristTip from '../../assets/img/tourist.png';
import vSiderBar from '../../components/Sidebar/index.vue';
import { getQueryVariable } from '@/utils';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { getSelfInfo } from '../../reactivity/login';
import { HomeRoute, homeChildrenRoutes } from '@/router/index';
import { filterRouter } from '@/permission';
import { Role } from '@/router';
import { ResponseResult } from '@/services/requests/types';

export default defineComponent({
  components: {
    vSiderBar,
  },
  setup() {
    const { iframeUrl } = useIframeUrl();
    const router = useRouter();
    const store = useStore();
    const init = ref(true);
    const login = () => {
      location.href = iframeUrl.value;
    };

    let token = sessionStorage.getItem('token') || '';
    if (!token) {
      console.log(11, token);
      token = getQueryVariable('access_token');
    }
    if (token) {
      sessionStorage.setItem('token', token);
      Cookies.set('logintype', 'MOBILEQ');
      Cookies.set('qqconnect_access_token', token);
      getSelfInfo().then((res) => {
        const { data } = res;
        console.log(data, 'data');
        if (data?.ret === 0) {
          if ([Role.manager, Role.factory, Role.owner, Role.writer].includes(data.data.roleId)) {
            store.commit('changeUserInfo', data.data);
            const routers = filterRouter(data.data.roleId, homeChildrenRoutes);
            HomeRoute.children = routers;
            const { path } = routers[0];
            HomeRoute.redirect = path;
            router.addRoute(HomeRoute);
            router.push(path);
          } else {
            init.value = false;
          }
        } else if (data?.ret === ResponseResult.NoRule) {
          init.value = false;
        }
      });
    }
    return {
      iframeUrl,
      nologintip,
      touristTip,
      login,
      init,
    };
  },
});
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 190px;
  height: 100%;
  background-color: #f2f3f7;
}
.tip-container {
  display: flex;
}
.tip {
  font-size: 14px;
  color: #303133;
  margin-bottom: 32px;
}
.login-container {
  margin-left: 58px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.btn {
  width: 90px;
  background-color: #4d74ff;
}
</style>
